import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/storage'

firebase.initializeApp({
    apiKey: "AIzaSyA0ZLycZc6lsTj3nbDxIRxNpzmRIuwVZuE",
    authDomain: "khadyan-4f6e4.firebaseapp.com",
    projectId: "khadyan-4f6e4",
    storageBucket: "khadyan-4f6e4.firebasestorage.app",
    messagingSenderId: "870591812094",
    appId: "1:870591812094:web:a48e9eb142dd14406e30af",
    measurementId: "G-JFX44D5W61"
});

const fb = firebase;

export default fb;