import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom';
import "../Sass/Home.scss"
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { Link as Links, Element } from 'react-scroll';
import emailjs from '@emailjs/browser';
import fb from "../Firebase/firebase";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UpArrow from '../Utilities/UpArrow';

// Images ===============================
// import banner from "../Image/Mockup/mockup.png"
import buy from "../Image/buy.jpg"
import banner from "../Image/banner.png"
import about from "../Image/about.png"
import Header from '../Utilities/Header';
import Footer from '../Utilities/Footer';
import mustard from "../Image/banner.png"
// =============================================

const DB = fb.firestore()
const CF = DB.collection('Khadyan');

const Home2 = () => {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        const href = window.location.href.substring(
            window.location.href.lastIndexOf('#') + 1
        );
        const element = document.getElementById(href);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }

    }, []);

    // products

    const responsive = {
        0: { items: 1 },
        // 568: { items: 2 },
        // 1024: { items: 3 },
    };

    const items = [
        <div className="products-card">
            <img src={mustard} alt="" />
            <div className="products-card-text">
                <h3>Kachi Ghani Mustard Oil</h3>

                <p>Pure Kachi Ghani Mustard Oil, traditionally known as ‘Sarson Ka Tel, is cold-pressed from the finest quality mustard seeds.</p>
                <p>Our dedication in maintaining purity ensures that this cooking oil retains its natural nutrients, robust aroma, and authentic flavors, staying true to the heart of traditional Indian cuisine. Pure cold-pressed Mustard oil is celebrated for its health benefits, particularly its wealth of monounsaturated and polyunsaturated fats, which support heart health. Ideal for Pickling, Frying, and Seasoning, Kachi Ghani Mustard Oil adds a unique pungency and depth to dishes, making it an indispensable addition to any kitchen focused on nutrition and taste.</p>

                <div className="btn">
                    <a href="#buy">Buy Now</a>
                    <Link to={"/product/kachi-ghani-mustard-oil"} href="#">Learn More</Link>
                </div>

            </div>
        </div >
    ];

    // products ends


    // contact form

    const form = useRef();
    const [userData, setUserData] = useState({
        Name: "",
        Email: "",
        Mobile: "",
        Quantity: "",
        Products: ""
    });

    let name, value;
    const postUserData = (e) => {
        name = e.target.name;
        value = e.target.value;
        setUserData({ ...userData, [name]: value });
    }

    //connect with firebase
    const SubmitData = async (e) => {
        e.preventDefault();
        const { Name, Email, Mobile, Quantity, Products } = userData;
        if (Name && Email && Mobile && Quantity && Products) {


            const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
            if (!emailRegex.test(userData.Email)) {
                toast.warning('Please enter a valid email address');
                return
            }

            if (typeof Mobile !== "undefined") {

                var pattern = new RegExp(/^[0-9\b]+$/);

                if (!pattern.test(userData.Mobile)) {
                    toast.warning("Please enter only number.");
                    return

                } else if (userData.Mobile.length !== 10) {
                    toast.warning("Please enter 10 digit Mobile number.");
                    return
                }
            }

            try {

                CF.add({
                    Name: Name,
                    Email: Email,
                    Mobile: Mobile,
                    Quantity: Quantity,
                    Products: Products
                }).then((docRef) => {
                    toast.success('Order Received Successfully');
                }).catch((error) => {
                    console.error("error:", error);
                });

                // emailjs.sendForm('service_91sqknx', 'template_4j6wwya', form.current, '6pbmyufjSURA60XeS')
                //     .then((result) => {
                //         console.log(result.text);

                //     }, (error) => {
                //         console.log(error.text);
                //     });

                setUserData({
                    Name: "",
                    Email: "",
                    Mobile: "",
                    Quantity: "",
                    Products: ""
                })

                // console.log("Document written with ID: ", docRef.id);
            } catch (e) {
                console.error("Error adding document: ", e);
                toast.warning("Error adding document")
            }
        }
        else {
            toast.warning('Please fill the data');
        }
    };

    // contact form dancing




    return (

        <>
            <ToastContainer />
            {/* <UpArrow /> */}

            <Header />

            <div className="banner">
                <img src={banner} alt="banner" />

                <div className="banner-text">
                    <h3>Growth Exceptional
                        Talent Let's Build
                        Success Together</h3>
                    <a href="#buy">Explore Now</a>
                </div>
            </div>

            <div className="khadyan">
                <Element name="about" className="element">
                    <section className="about" id='about'>

                        <div className="title">
                            <h4>About <span>us</span> </h4>
                        </div>
                        <div className="about-body">
                            <div className="about-img">
                                <img src={about} alt="" />
                            </div>
                            <div className="about-text">
                                <p>VCM Enterprises is a leading recruitment and placement firm dedicated to connecting talented individuals with rewarding career paths. We specialize in matching skilled professionals with suitable job opportunities across diverse industries.</p>
                                <ul>
                                    <li>We prioritize your career aspirations and work diligently to find the ideal job match that aligns with your skills, experience, and goals.</li>
                                    <li>We collaborate with reputable companies to source top-notch talent and help them build high-performing teams.</li>
                                    <li>We possess in-depth knowledge of various industries, enabling us to identify the best opportunities for our candidates.</li>
                                </ul>


                            </div>
                        </div>
                    </section>
                </Element>
                <section className="products">
                    <div className="title">
                        <h4>Talent <span>Acquisition</span>  and <span>Staffing</span>  </h4>
                        <p></p>
                    </div>
                    <div className="products-body">
                        <div className="products-card">
                            <h3>Executive Search</h3>
                            <p>Our specialized executive search team identifies and recruits top-tier talent for C-suite and senior leadership positions. We leverage our extensive network, advanced search methodologies, and in-depth industry knowledge to deliver exceptional results.</p>
                        </div >
                        <div className="products-card">
                            <h3>Permanent Placement</h3>
                            <p>VCM Enterprises is your trusted partner for finding highly skilled professionals to fill critical roles within your organization. We conduct thorough candidate assessments, reference checks, and cultural fit evaluations to ensure long-term success.</p>
                        </div >
                        <div className="products-card">
                            <h3>Contract Staffing</h3>
                            <p>We offer flexible staffing solutions to meet your short-term and project-based needs. Our skilled contract professionals can quickly adapt to evolving business requirements, providing seamless integration into your team.</p>
                        </div >
                        <div className="products-card">
                            <h3>RPO (Recruitment Process Outsourcing)</h3>
                            <p>Our RPO services streamline your recruitment process, reduce costs, and enhance efficiency. We take over end-to-end recruitment responsibilities, including sourcing, screening, interviewing, and onboarding.</p>
                        </div >
                    </div>
                </section>

                <div className="benefits">
                    <div className="title">
                        <h4>Transportation <span>Solutions</span></h4>
                        <p></p>
                    </div>
                    <div className="benefits-body">
                        <div className="benefits-left">
                            <div className="benefits-card">
                                <h3>Driver Services</h3>
                                <p>Providing skilled and reliable drivers for city travel needs, including corporate transportation and personal use.</p>
                            </div>
                        </div>
                        {/* <img src={benefits} alt="oil" /> */}
                        <div className="benefits-left">
                            <div className="benefits-card">
                                <h3>Taxi Services</h3>
                                <p>Offering efficient and convenient taxi services to cater to diverse transportation requirements.</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="blog">
                    <div className="title">
                        <h4>Our Blogs</h4>
                        <p></p>
                    </div>
                    <div className="blog-body">
                        <div className="blog-card">
                            <img src={blog} alt="" />
                            <h3>Blog 1</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellat delectus praesentium earum voluptatum quidem autem doloremque magnam accusamus! Aperiam dolorem non at exercitationem error nihil quam ullam perferendis quibusdam tempora!</p>
                        </div>

                        <div className="blog-card">
                            <img src={blog} alt="" />
                            <h3>Blog 1</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellat delectus praesentium earum voluptatum quidem autem doloremque magnam accusamus! Aperiam dolorem non at exercitationem error nihil quam ullam perferendis quibusdam tempora!</p>
                        </div>

                        <div className="blog-card">
                            <img src={blog} alt="" />
                            <h3>Blog 1</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellat delectus praesentium earum voluptatum quidem autem doloremque magnam accusamus! Aperiam dolorem non at exercitationem error nihil quam ullam perferendis quibusdam tempora!</p>
                        </div>
                    </div>
                </div> */}

                {/* <div className="fruits">
                    <div className="fruits-text">
                        <h3>We also deal in Fruits & Vegetables</h3>
                        <a href="tel:918708779363">Call Us</a>
                    </div>
                </div> */}

                <section className="contact" id="buy">
                    <div className="title">
                        <h4>Get in <span>Touch</span> </h4>
                        <p></p>
                    </div>
                    <div className="contact-body">
                        <img src={buy} alt="buy now" />
                        <form action="POST" ref={form}>
                            <div className="form">
                                <input type="text" placeholder='Full Name'
                                    name='Name'
                                    value={userData.Name}
                                    onChange={postUserData}
                                    required
                                />
                                <input type="email" placeholder='Email Address'
                                    name='Email'
                                    value={userData.Email}
                                    onChange={postUserData}
                                    required
                                />
                                <input type="text" placeholder='Mobile'
                                    name='Mobile'
                                    value={userData.Mobile}
                                    onChange={postUserData}
                                    required
                                />
                                <input style={{ display: "none" }}
                                    type="text"
                                    name="productName"
                                    value="Contact Us"
                                />
                                {/* <input type="number" placeholder='Quantity' name='Quantity'
                                min={1}
                                value={userData.Quantity}
                                onChange={postUserData}
                                required
                            /> */}
                                <select id="" className="form-control" name='Products'
                                    value={userData.Products}
                                    onChange={postUserData} >
                                    <option value="retailer/self use" selected hidden>Select Service</option>
                                    <option value="Talent Acquisition and Staffing">Talent Acquisition and Staffing</option>
                                    <option value="Transportation Solutions">Transportation Solutions</option>
                                </select>
                                <textarea name="" id="" placeholder='Message'></textarea>
                            </div>

                            <button>Send</button>
                        </form>
                    </div>
                </section>
            </div>
            <Footer />

        </>

    )
}

export default Home2