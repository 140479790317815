import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './Components/Sass/Home.scss'
// import Home from './Components/Pages/Home';
import Home2 from './Components/Pages/Home2';
import MustardOil from './Components/Pages/MustardOil';
import RefinedOil from './Components/Pages/RefinedOil';
import Welcome from './Components/Pages/Welcome';

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home2 />} />
          {/* <Route path="/product/kachi-ghani-mustard-oil" element={<MustardOil />} />
          <Route path="/product/refined-soyabean-oil" element={<RefinedOil />} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
